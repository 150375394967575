/* GLOBAL STYLES */

html {
  font-size: 16px;
  width: 100%;
  height: 100%;
}

/* App */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

:root {
  --eiddesk-sidebar-width: 240px;
  --eiddesk-min-page-width: 1024px;
  --eiddesk-max-page-width: 1920px;
  --eiddesk-max-content-width: calc(var(--eiddesk-max-page-width) - var(--eiddesk-sidebar-width));
  --procivis-sidebar-width: var(--eiddesk-sidebar-width);
  --procivis-min-page-width: var(--eiddesk-min-page-width);
  --procivis-max-page-width: var(--eiddesk-max-page-width);
  --procivis-max-content-width: var(--eiddesk-max-content-width);
}

/* Theme */
/* The App Flavor based theme styles are loaded by importing '@appconf/theme' in index.tsx */

/* Dropdown Selection */
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  font-weight: 600 !important; /* Should be same as @select-item-selected-font-weight */
}

/* Page Header */
.ant-layout-header {
  padding-left: 0;
  padding-right: 0;
  height: auto;
}

.ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.ant-layout-sider {
  position: sticky;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.actionsColumn {
  width: 1px;
  white-space: nowrap;
}
